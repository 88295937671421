import GeneralBanner from "components/banner";
import AllRooms from "./components/rooms-all";

const RoomsPage = () => {

    return (
        <>
            <GeneralBanner pagename="Rooms" shortdesc = "Look at our Rooms and choose the one of your choice" />
            <AllRooms />
        </>
    );
}

export default RoomsPage;
