import { Link } from "react-router-dom";
import { baseURL } from "config/base";
const BlogHome = ({data}) => {
    return (
        <>
            <div className="blog hom-com">
                <div className="container">
                    <div className="row">
                        <div className="hom1-title">
                            <h2>Blog</h2>
                            <div className="head-title">
                                <div className="hl-1"></div>
                                <div className="hl-2"></div>
                                <div className="hl-3"></div>
                            </div>
                            <p>The Latest Updates</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="bot-gal h-blog">
                            <div className="row">
                                {data?.slice(0, 3)?.map((blog) => (
                                    <div className="col-md-4" key={blog.id}>
                                        <Link
                                            to={`/blogs/${blog?.id}`}
                                            state={data?.filter(
                                                (data) => data.id === blog.id
                                            )}
                                        >
                                            {" "}
                                            <img
                                                className="card-top-img"
                                                src={`${baseURL}${blog.featured_image}`}
                                                alt="Card"
                                                loading="lazy"
                                                style={{height:"250px"}}
                                            />
                                            <h5>{blog.title}</h5>{" "}
                                            <span>
                                                {(new Date (blog?.created_at))?.toLocaleDateString(
                                                    "en-GB"
                                                )}
                                            </span>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlogHome;
