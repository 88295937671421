import { useState, useEffect } from "react";
import axios from "config/axios";
import { baseURL } from "config/base";
const AllServices = () => {
    const [data, setData] = useState([]);

    const getServices = async () => {
        try {
            const response = await axios.get("services");
            setData(response.data.services);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getServices();
    }, []);
    return (
        <>
            <div className="inn-body-section pad-bot-50">
                <div className="container">
                    <div className="row inn-page-com">
                        <div className="page-head">
                            <h2>Hotel Services</h2>
                            <div className="head-title">
                                <div className="hl-1"></div>
                                <div className="hl-2"></div>
                                <div className="hl-3"></div>
                            </div>
                            <p>List Of Our Services.</p>
                        </div>
                        <div className="col-md-12">
                            {data?.map((service) => (
                                <div className="row inn-services in-blog" key={service.id}>
                                    <div className="col-md-4">
                                        <img
                                            src={`${baseURL}${service.image}`}
                                            alt=""
                                            style={{height: "200px"}}
                                        />
                                    </div>
                                    <div className="col-md-8">
                                        <h3>{service.title}</h3>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: service.description,
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AllServices;
