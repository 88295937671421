const Bars = ({data, baseURL}) => {
    return (
        <>
            <div className="row inn-page-com">
                <div className="page-head sp-mar-top-50 inn-sec-head-spa">
                    <h2>Our Bar</h2>
                    <div className="head-title">
                        <div className="hl-1"></div>
                        <div className="hl-2"></div>
                        <div className="hl-3"></div>
                    </div>
                    <p>Enjoy Drinks from our Bar</p>
                </div>
                {data
                    ?.filter((bar) => bar.type === "bar")
                    ?.map((bar) => (
                        <div className="col-md-12" key={bar.id}>
                            <div className="row">
                                <div className="res-menu">
                                    {" "}
                                    <img
                                        src={`${baseURL}${bar.image}`}
                                        alt=""
                                    />
                                    <h3>
                                        {bar.title}
                                    </h3>{" "}
                                    {/* <span className="menu-item">
                                        Tomato soup with croutons, small ceasar
                                        salad, apple juice
                                    </span>{" "} */}
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </>
    );
}

export default Bars;
