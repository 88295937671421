import { useState, useEffect } from "react";
import axios from "config/axios";
import { baseURL } from "config/base";
const Gallerys = () => {
    const [data, setData] = useState([]);
     const getGallery = async () => {
         try {
             const response = await axios.get("gallery");
             setData(response.data.gallery);
         } catch (error) {
             console.log(error);
         }
     };
      useEffect(() => {
          getGallery();
      }, []);
    return (
        <>
            <div className="inn-body-section pad-bot-55">
                <div className="container">
                    <div className="row">
                        <div className="page-head">
                            <h2>Photo Gallery </h2>
                            <div className="head-title">
                                <div className="hl-1"></div>
                                <div className="hl-2"></div>
                                <div className="hl-3"></div>
                            </div>
                        </div>
                        {data?.map((photo) => (
                            <div className="col-md-4" key={photo.id}>
                                <div className="gall-grid">
                                    <img
                                        className="materialboxed"
                                        data-caption="A picture of a way"
                                        src={`${baseURL}${photo.image}`}
                                        alt=""
                                    />{" "}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Gallerys;
