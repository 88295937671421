import GeneralBanner from "components/banner";
import { useLocation } from "react-router-dom";
import { baseURL } from "config/base";
const RoomSingle = () => {
    const location = useLocation();
    const data = location.state;
    // console.log(data)
    return (
        <>
            <GeneralBanner
                pagename={"View Room"}
                shortdesc={"More Room Details"}
            />
            <div className="hom-com">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="row">
                                {data?.slice(0, 1)?.map((room) => (
                                    <>
                                        <div
                                            className="hp-section"
                                            key={room.id}
                                        >
                                            <div className="hp-sub-tit">
                                                <h4>
                                                    <span>{room.title}</span>
                                                </h4>
                                            </div>
                                            <div className="hp-amini">
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: room.long_description,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="hp-section">
                                            <div className="hp-sub-tit">
                                                <h4>
                                                    <span>Amenities</span> Room
                                                </h4>
                                            </div>
                                            <div className="hp-amini">
                                                <ul>
                                                    <li>
                                                        <img
                                                            src="assets/images/icon/a2.png"
                                                            alt=""
                                                        />{" "}
                                                        Friendly workspace
                                                    </li>
                                                    <li>
                                                        <img
                                                            src="assets/images/icon/a5.png"
                                                            alt=""
                                                        />{" "}
                                                        Instant Book
                                                    </li>
                                                    <li>
                                                        <img
                                                            src="assets/images/icon/a4.png"
                                                            alt=""
                                                        />{" "}
                                                        Wireless Internet
                                                    </li>
                                                    <li>
                                                        <img
                                                            src="assets/images/icon/a8.png"
                                                            alt=""
                                                        />{" "}
                                                        Free parking on premises
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="hp-section">
                                            <div className="hp-sub-tit">
                                                <h4>
                                                    <span>Photo Gallery</span>{" "}
                                                </h4>
                                            </div>
                                            <div className="">
                                                <div className="h-gal">
                                                    <ul>
                                                        <li>
                                                            <img
                                                                className="materialboxed"
                                                                data-caption="Hotel Captions"
                                                                src={`${baseURL}${room.featured_image}`}
                                                                loading="lazy"
                                                                alt=""
                                                            />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="hp-call hp-right-com">
                                <div className="hp-call-in">
                                    {" "}
                                    <img
                                        src="assets/images/icon/dbc4.png"
                                        alt=""
                                    />
                                    <h3>
                                        <span>
                                            Check Availability. Call us!
                                        </span>{" "}
                                        +256-414-671-210
                                    </h3>
                                    <small>
                                        We are available 24/7 Monday to Sunday
                                    </small>{" "}
                                    <a href="tel:+256-414-671-210">Call Now</a>{" "}
                                </div>
                            </div>

                            <div className="hp-card hp-right-com">
                                <div className="hp-card-in">
                                    <h3>We Accept</h3> <span></span>{" "}
                                    <img src="assets/images/card.png" alt="" />{" "}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RoomSingle;
