import { Link } from "react-router-dom";

const GeneralBanner = ({ pagename, shortdesc }) => {
    return (
        <>
            <div className="inn-banner">
                <div className="container">
                    <div className="row">
                        <h4>{pagename}</h4>
                        <p style={{color: "#fff"}}>
                            {shortdesc}
                        </p>
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link>{pagename}</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GeneralBanner;
