import { useNavigate } from "react-router-dom";
import Logo from "assets/logos/favicon.png";
const Missing = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="d-flex align-items-center justify-content-center vh-100 mt-5" style={{marginTop : "200px"}}>
                <div className="text-center">
                    <img src={Logo} alt="" height="100px" />
                    <h1 className="display-1 fw-bold">404</h1>
                    <p className="fs-3">
                        {" "}
                        <span className="text-danger">Opps!</span> Page Not
                        Found.
                    </p>
                    <p className="lead fs-2">This Page Has Been Moved.</p>
                    <button
                        className="btn btn-dark"
                        onClick={() => navigate(-1)}
                    >
                        Go Back
                    </button>
                </div>
            </div>
        </>
    );
};

export default Missing;
