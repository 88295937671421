import {useState} from "react"
import { useForm } from "react-hook-form";
import axios from "config/axios";
const Booking = () => {
    const [inputType, setInputType] = useState("text");

    const handleInputChange = () => {
        setInputType("date");
    };
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const [disabled, setDisabled] = useState(false);
    const onSave = async(data) => {

        try {
            setDisabled(true);
            const response =  await axios.post("booking", data);
            console.log(response.data);
            alert("Booking Successful");
            reset();
            setDisabled(false);


        } catch (error) {
            console.log(error);
            setDisabled(false);

        }
    }

    return (
        <>
            <div className="inn-body-section inn-booking">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="book-title">
                                <h2>Booking</h2>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="book-form inn-com-form">
                                <form
                                    className="col s12"
                                    onSubmit={handleSubmit(onSave)}
                                >
                                    <div className="row">
                                        <div className="input-field col s6">
                                            <input
                                                type="text"
                                                className="validate"
                                                name="fullname"
                                                id="fullname"
                                                {...register("fullname", {
                                                    required: true,
                                                })}
                                            />
                                            <label htmlFor="">Full Name</label>
                                            {errors?.fullname?.type ===
                                                "required" && (
                                                <p className="text-small m-0 text-start text-danger help-block">
                                                    This field is required
                                                </p>
                                            )}
                                        </div>
                                        <div className="input-field col s6">
                                            <input
                                                type="text"
                                                className="validate"
                                                name="email"
                                                id="email"
                                                {...register("email", {
                                                    required: true,
                                                    email: true,
                                                })}
                                            />
                                            <label htmlFor="">Email</label>
                                            {errors?.email?.type ===
                                                "required" && (
                                                <p className="text-small m-0 text-start text-danger help-block">
                                                    This field is required
                                                </p>
                                            )}
                                            {errors?.email?.type ===
                                                "email" && (
                                                <p className="text-small m-0 text-start text-danger help-block">
                                                    This field is an email
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="input-field col s6">
                                            <input
                                                type="tel"
                                                className="validate"
                                                name="phone"
                                                id="phone"
                                                {...register("phone", {
                                                    required: true,
                                                })}
                                            />
                                            <label htmlFor="phone">Phone</label>
                                            {errors?.phone?.type ===
                                                "required" && (
                                                <p className="text-small m-0 text-start text-danger help-block">
                                                    This field is required
                                                </p>
                                            )}
                                        </div>
                                        <div className="input-field col s6">
                                            <label htmlFor="from">
                                                Arrival Date
                                            </label>
                                            <input
                                                type={inputType}
                                                id="from"
                                                name="from"
                                                onClick={handleInputChange}
                                                {...register("from", {
                                                    required: true,
                                                })}
                                            />
                                            {errors?.from?.type ===
                                                "required" && (
                                                <p className="text-small m-0 text-start text-danger help-block">
                                                    This field is required
                                                </p>
                                            )}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="input-field col s12">
                                            <textarea
                                                id="textarea1"
                                                className="materialize-textarea"
                                                name="address"
                                                required
                                                data-length="120"
                                                {...register("address", {
                                                    required: true,
                                                })}
                                            ></textarea>
                                            <label>Address</label>
                                            {errors?.address?.type ===
                                                "required" && (
                                                <p className="text-small m-0 text-start text-danger help-block">
                                                    This field is required
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="input-field col s12">
                                            <button
                                                type="submit"
                                                className="form-btn btn-block btn-lg"
                                                disabled={disabled}
                                            >
                                                {!disabled && "Submit"}{" "}
                                                {disabled &&
                                                    "Please Wait ....."}
                                            </button>{" "}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Booking;
