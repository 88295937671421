import { Link } from "react-router-dom";

const SampleAminities = () => {
    return (
        <>
            <div className="offer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="offer-l">
                                {" "}
                                <span className="ol-1"></span>{" "}
                                <span className="ol-2">
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                </span>{" "}
                                <span className="ol-4">Standardized Rooms</span>{" "}
                                <span className="ol-3"></span>{" "}
                                <span className="ol-5"></span>
                                <ul>
                                    <li>
                                        <Link
                                            t="/"
                                            className="waves-effect waves-light btn-large offer-btn"
                                        >
                                            <img
                                                src="assets/images/icon/dis1.png"
                                                alt=""
                                            />
                                        </Link>
                                        <span>Free WiFi</span>
                                    </li>
                                    <li>
                                        <Link
                                            to="/"
                                            className="waves-effect waves-light btn-large offer-btn"
                                        >
                                            <img
                                                src="assets/images/icon/h2.png"
                                                alt=""
                                            />{" "}
                                        </Link>
                                        <span>Breakfast</span>
                                    </li>

                                    <li>
                                        <Link
                                            to="/"
                                            className="waves-effect waves-light btn-large offer-btn"
                                        >
                                            <img
                                                src="assets/images/icon/dis4.png"
                                                alt=""
                                            />{" "}
                                        </Link>
                                        <span>Television</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="offer-r">
                                <div className="or-1">
                                    {" "}
                                    <span className="or-11">go</span>{" "}
                                    <span className="or-12">safe</span>{" "}
                                </div>
                                <div className="or-2">
                                    {" "}
                                    <span className="or-21">Safe</span>{" "}
                                    <span className="or-22">Stays </span>{" "}
                                    <span className="or-23">.</span>{" "}
                                    <span className="or-24">
                                        24/7 Security
                                    </span>{" "}
                                    <span className="or-25"></span>{" "}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>{" "}
            /
        </>
    );
};

export default SampleAminities;
