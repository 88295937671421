import { Link } from "react-router-dom";
import Image from "assets/images/IMG_0488-min.jpg";
const BookingHome = () => {
    return (
        <>
            <div
                className="hom-body-section pt-4"
                style={{ background: "#cccccc", marginTop: "50px" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="home-bod col-md-6">
                            <div className="home-bod-1">
                                <h4>
                                    <span>Book</span> Now
                                </h4>
                                <p style={{ color: "#000" }}>
                                    Make Heartland Cottages Arua your home away
                                    from home. Book your stay with us and
                                    experience the epitome of hospitality and
                                    comfort
                                </p>
                            </div>

                            <div className="home-bod-2">
                                <ul>
                                    <li>
                                        <Link
                                            to="/rooms"
                                            className="waves-effect waves-light btn-large wed-pop-ser-btn"
                                        >
                                            <img
                                                src="assets/images/icon/h1.png"
                                                alt=""
                                            />{" "}
                                            Rooms
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/booking"
                                            className="waves-effect waves-light btn-large wed-pop-ser-btn"
                                        >
                                            <img
                                                src="assets/images/icon/h7.png"
                                                alt=""
                                            />{" "}
                                            Booking
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/restaurants-bar"
                                            className="waves-effect waves-light btn-large wed-pop-ser-btn"
                                        >
                                            <img
                                                src="assets/images/icon/h2.png"
                                                alt=""
                                            />{" "}
                                            Food & Bar
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/about-us"
                                            className="waves-effect waves-light btn-large wed-pop-ser-btn"
                                        >
                                            <img
                                                src="assets/images/icon/h5.png"
                                                alt=""
                                            />{" "}
                                            About Us
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="home-bod col-md-6" >
                            <img src={Image} height="350px" style={{float: 'right'}} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BookingHome;
