import { Link } from "react-router-dom";
import Logo from "assets/logos/heartland.png";
// import Payment from "assets/img/money.png";
import axios from "config/axios";
import { useState } from "react";
const Footer = () => {
    const [email, setEmail] = useState([]);

    const onSave = async(event) => {
        event.preventDefault();
        try {
            const response = await axios.post("subscribe", {email});
            alert(response.data.message);
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            <footer className="site-footer clearfix bg-dark">
                <div className="sidebar-container">
                    <div className="sidebar-inner">
                        <div className="widget-area clearfix">
                            <div className="widget widget_azh_widget">
                                <div>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-3 foot-logo">
                                                {" "}
                                                <img src={Logo} alt="logo" />
                                                <p className="hasimg">
                                                    Visit Heartland Cottages
                                                    Arua and create memories
                                                    that will last a lifetime.
                                                    Book now
                                                </p>
                                                <p className="hasimg">
                                                    Exceptional Services
                                                </p>
                                            </div>
                                            <div className="col-sm-12 col-md-3">
                                                <h4>Links</h4>
                                                <ul className="one-columns">
                                                    <li>
                                                        <Link href="/booking">
                                                            Booking
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/contact-us">
                                                            Contact Us
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/about-us">
                                                            About Us
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/restaurants-bar">
                                                            Restaurant & Bar
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/blogs">
                                                            Blogs
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-12 col-md-3">
                                                <h4>Services</h4>
                                                <ul className="one-columns">
                                                    <li>
                                                        <Link to="/services">
                                                            Accommodation
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/services">
                                                            Housekeeping
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/services">
                                                            Room Service
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/services">
                                                            24/7 Security
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-12 col-md-3">
                                                <h4>Address</h4>
                                                <p>
                                                    Heartland Cottages ,<br />{" "}
                                                    Euata, Arua city <br />{" "}
                                                    Uganda
                                                </p>
                                                <p>
                                                    <span>
                                                        +256-414-671-210
                                                    </span>{" "}
                                                    <span>
                                                        info@heartlandcottagesarua.com{" "}
                                                    </span>{" "}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="foot-sec2">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-3">
                                                <h4>Payment Options</h4>
                                                <p className="hasimg">
                                                    {" "}
                                                    <img
                                                        src="assets/images/payment.png"
                                                        alt="payment"
                                                    />{" "}
                                                </p>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <h4>Subscribe Now</h4>
                                                <form onSubmit={onSave}>
                                                    <div className="foot-subsc row">
                                                        <div className="col-md-9 col-sm-12">
                                                            <input
                                                                type="email"
                                                                placeholder="Enter your email address"
                                                                className="form-control border-0"
                                                                value={email}
                                                                onChange={(e) =>
                                                                    setEmail(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />{" "}
                                                        </div>
                                                        <div className="col-md-3 col-sm-12">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-sucess"
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="col-sm-12 col-md-3 foot-social">
                                                <h4>Follow with us</h4>
                                                <ul>
                                                    <li>
                                                        <a href="https://facebook.com/">
                                                            <i
                                                                className="fa fa-facebook"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </a>{" "}
                                                    </li>

                                                    <li>
                                                        <a href="https://twitter.com/">
                                                            <i
                                                                className="fa fa-twitter"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </a>{" "}
                                                    </li>
                                                    <li>
                                                        <a href="https://whatsapp.com/">
                                                            <i
                                                                className="fa fa-whatsapp"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </a>{" "}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <section className="copy">
                <div className="container row">
                    <p
                        className="col-md-6 col-sm-12"
                        style={{ fontSize: "12px", padding: "10px" }}
                    >
                        &copy; Heartland Cottages Arua
                    </p>
                    <p
                        className="text-sm col-md-6 col-sm-12 p-3"
                        style={{ fontSize: "12px", padding: "10px" }}
                    >
                        Developed By:{" "}
                        <a
                            href="https://furahatechnologies.com"
                            target="_blank"
                            rel="noreferrer"
                            style={{ fontSize: "12px", color: "#fff" }}
                        >
                            {" "}
                            Furaha Technologies
                        </a>
                    </p>
                </div>
            </section>
        </>
    );
};

export default Footer;
