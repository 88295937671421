import Bars from "./bars";

const Restuarant = ({data, baseURL}) => {
    return (
        <>
            <div className="inn-body-section pad-bot-65">
                <div className="container">
                    <div className="row inn-page-com">
                        <div className="page-head">
                            <h2>Our Menu</h2>
                            <div className="head-title">
                                <div className="hl-1"></div>
                                <div className="hl-2"></div>
                                <div className="hl-3"></div>
                            </div>
                            <p>Enjoy Some Of Food And Drinks</p>
                        </div>
                        {data
                            ?.filter((food) => food.type === "restaurant")
                            ?.map((food) => (
                                <div className="col-md-12" key={food.id}>
                                    <div className="row">
                                        <div className="res-menu">
                                            {" "}
                                            <img
                                                src={`${baseURL}${food.image}`}
                                                alt=""
                                            />
                                            <h3>
                                                {food.title}{" "}
                                                {/* <span>$45</span> */}
                                            </h3>{" "}
                                            {/* <span className="menu-item">
                                                Tomato soup with croutons, small
                                                ceasar salad, apple juice
                                            </span>{" "} */}
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                    <Bars data={data} baseURL={baseURL} />
                </div>
            </div>
        </>
    );
};

export default Restuarant;
