const ContactUssBody = () => {
    return (
        <>
            <div className="inn-body-section">
                <div className="container">
                    <div className="row">
                        <div className="page-head">
                            <h2>Contact Us</h2>
                            <div className="head-title">
                                <div className="hl-1"></div>
                                <div className="hl-2"></div>
                                <div className="hl-3"></div>
                            </div>
                            <p>Feel Free To Contact Us</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 col-sm-6 col-xs-12 new-con">
                            <h2>
                                Heartland <br /> Cottages <br /> <span>Arua</span>
                            </h2>

                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12 new-con">
                            {" "}
                            <img src="assets/images/icon/20.png" alt="" />
                            <h4>Address</h4>
                            <p>
                                Heartland Cottages , <br /> Euata, Arua city{" "}
                                <br /> Uganda
                            </p>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12 new-con">
                            {" "}
                            <img src="assets/images/icon/22.png" alt="" />
                            <h4>CONTACT INFO:</h4>
                            <p>
                                {" "}
                                <a
                                    href="tel:+256-414-671-210"
                                    className="contact-icon"
                                >
                                    +256-414-671-210
                                </a>
                                <br />{" "}
                                <a
                                    href="mailto:info@heartlandcottagesarua.com"
                                    className="contact-icon"
                                >
                                    info@heartlandcottagesarua.com
                                </a>{" "}
                            </p>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12 new-con">
                            {" "}
                            <img src="assets/images/icon/21.png" alt="" />
                            <h4>Our Socials</h4>
                            <p>
                                <a
                                    href="https://facebook.com/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="px-5 text-primary"
                                    style={{
                                        paddingRight: "15px",
                                        color: "blue",
                                    }}
                                >
                                    <i className="fa fa-2x fa-facebook"></i>
                                </a>
                                <a
                                    href="https://instagram.com/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="px-5 text-primary"
                                    style={{
                                        paddingRight: "15px",
                                        color: "red",
                                    }}
                                >
                                    <i className="fa fa-2x fa-instagram"></i>
                                </a>
                                <a
                                    href="https://twitter.com/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="px-5 text-primary"
                                    style={{
                                        paddingRight: "15px",
                                        color: "blue",
                                    }}
                                >
                                    <i className="fa fa-2x fa-twitter"></i>
                                </a>{" "}
                                <a
                                    href="https://whatsapp.com/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="px-5 text-primary"
                                    style={{
                                        paddingRight: "15px",
                                        color: "green",
                                    }}
                                >
                                    <i className="fa fa-2x fa-whatsapp"></i>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="contact-map">
                    <iframe
                        title="google-maps"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.4285948194906!2d30.917999374776418!3d2.9784704541785323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x176e617663b8cd79%3A0xae9416dde840c90b!2sHeartland%20Cottages%20Arua!5e0!3m2!1sen!2sug!4v1691652582853!5m2!1sen!2sug"
                        allowFullScreen
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            </div>
        </>
    );
}

export default ContactUssBody;
