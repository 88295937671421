import { useEffect, useState } from "react";
import BlogHome from "./components/blog-home";
import BookingHome from "./components/bookinghome";
import RoomsHomePage from "./components/rooms";
import SampleAminities from "./components/sample-aminities";
import SliderHome from "./components/slider";
import MeetingsAndSpacesHome from "./components/spaces-meeting";
import axios from "config/axios";
const HomePage = () => {

    const [roomData, setRoomData] = useState([]);
    const [spaceData, setSpaceData] = useState([]);
    const [blogData, setBlogData] = useState([]);

    const getRooms = async() =>{
        try {
            const response = await axios.get("rooms");
            setRoomData(response.data.rooms);

        } catch (error) {
            console.log(error)
        }

    }
     const getSpaces = async () => {
         try {
             const response = await axios.get("spaces");
             setSpaceData(response.data.spaces);
         } catch (error) {
             console.log(error);
         }
     };
     const getBlog = async () => {
         try {
             const response = await axios.get("posts");
             setBlogData(response.data.posts);
         } catch (error) {
             console.log(error);
         }
     };
    useEffect(()=>{
        getRooms();
        getSpaces();
        getBlog();
    }, []);

    return (
        <>
            <SliderHome />
            <RoomsHomePage data={roomData} />
            <SampleAminities />
            <MeetingsAndSpacesHome data={spaceData} />
            <BookingHome />
            <BlogHome data={blogData} />
        </>
    );
}

export default HomePage;
