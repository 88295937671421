import GeneralBanner from "components/banner";
import { Link } from "react-router-dom";
import { baseURL } from "config/base";
import axios from "config/axios";
import { useState, useEffect } from "react";
const Blog = () => {
    const [blogData, setBlogData] = useState([]);
    const getBlog = async () => {
        try {
            const response = await axios.get("posts");
            setBlogData(response.data.posts);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getBlog();
    }, []);
    return (
        <>
            <GeneralBanner pagename={"Blogs"} shortdesc={"All Blogs"} />
            <div className="inn-body-section pad-bot-50">
                <div className="container">
                    <div className="row inn-page-com">
                        <div className="page-head">
                            <h2>Blog Posts</h2>
                            <div className="head-title">
                                <div className="hl-1"></div>
                                <div className="hl-2"></div>
                                <div className="hl-3"></div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            {blogData?.map((blog) => (
                                <div
                                    className="row inn-services in-blog"
                                    key={blog.id}
                                >
                                    <div className="col-md-4">
                                        {" "}
                                        <img
                                            src={`${baseURL}${blog.featured_image}`}
                                            alt=""
                                            style={{height:"150px"}}
                                        />{" "}
                                    </div>
                                    <div className="col-md-8">
                                        <h3>Restaurant: special 30% offer</h3>{" "}
                                        <span className="blog-date">
                                            {new Date(
                                                blog?.created_at
                                            )?.toLocaleDateString("en-GB")}
                                        </span>{" "}
                                        <p>{blog.slug}</p>
                                        <Link
                                            to={`/blogs/${blog?.id}`}
                                            state={blogData?.filter(
                                                (data) => data.id === blog.id
                                            )}
                                            className="waves-effect waves-light inn-re-mo-btn"
                                        >
                                            Read More
                                        </Link>{" "}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="col-md-4">
                            <div className="head-typo typo-com rec-post">
                                <h3>Recent Posts</h3>
                                <ul>
                                    {blogData?.slice(0,5)?.map((blog) => (
                                        <li key={blog.id}>
                                            <div className="rec-po-img">
                                                {" "}
                                                <img
                                                    src={`${baseURL}${blog.featured_image}`}
                                                    height="50px"
                                                    alt=""
                                                />{" "}
                                            </div>
                                            <div className="rec-po-title">
                                                {" "}
                                                <Link
                                                    to={`/blogs/${blog?.id}`}
                                                    state={blogData?.filter(
                                                        (data) =>
                                                            data.id === blog.id
                                                    )}
                                                >
                                                    <h4>{blog.title}</h4>
                                                </Link>
                                                <p>{blog.slug}</p>{" "}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Blog;
