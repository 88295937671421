import { Link } from "react-router-dom";
import Logo from "assets/logos/heartland.png";
const HeaderMobile = () => {
    return (
        <>
            <section>
                <div className="mm">
                    <div className="mm-inn">
                        <div className="mm-logo">
                            <Link href="/">
                                <img src={Logo} alt="" />
                            </Link>
                        </div>
                        <div className="mm-icon">
                            <span>
                                <i
                                    className="fa fa-bars show-menu"
                                    aria-hidden="true"
                                ></i>
                            </span>
                        </div>
                        <div className="mm-menu">
                            <div className="mm-close">
                                <span>
                                    <i
                                        className="fa fa-times hide-menu"
                                        aria-hidden="true"
                                    ></i>
                                </span>
                            </div>
                            <ul>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/rooms">Rooms</Link>
                                </li>
                                <li>
                                    <Link to="/about-us">About Us</Link>
                                </li>
                                <li>
                                    <Link to="/services">Services</Link>
                                </li>
                                <li>
                                    <Link to="/restaurants-bar">
                                        Restaurant&Bar
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/gallery">Gallery</Link>
                                </li>
                                <li>
                                    <Link to="/blogs">Blogs</Link>
                                </li>
                                <li>
                                    <Link to="/contact-us">Contact Us</Link>
                                </li>
                                <li>
                                    <Link
                                        to="/booking"
                                        className="waves-effect waves-light"
                                    >
                                        Book Now
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeaderMobile;
