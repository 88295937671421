import GeneralBanner from "components/banner";
import ContactUssBody from "./components/contactus-body";

const ContactUs = () => {
    return (
        <>
        <GeneralBanner pagename={"Contact Us"} shortdesc={"Please feel free to contact us"} />
        <ContactUssBody />
        </>
     );
}

export default ContactUs;
