import { Link } from "react-router-dom";
import { baseURL } from "config/base";
const RoomsHomePage = ({data}) => {
    return (
        <>
            <div className="hom1 hom-com pad-bot-40">
                <div className="container">
                    <div className="row">
                        <div className="hom1-title">
                            <h2>Our Rooms</h2>
                            <div className="head-title">
                                <div className="hl-1"></div>
                                <div className="hl-2"></div>
                                <div className="hl-3"></div>
                            </div>
                            <p>
                                Our Hotel provides a wide range of rooms to
                                ensure a comfortable and enjoyable stay for our
                                guests.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="to-ho-hotel">
                            {data?.slice(0, 3)?.map((room) => (
                                <div className="col-md-4" key={room.id}>
                                    <div className="to-ho-hotel-con">
                                        <Link
                                            to={`/rooms/${room?.id}`}
                                            state={data?.filter(
                                                (data) => data.id === room.id
                                            )}
                                            className="to-ho-hotel-con-1"
                                        >
                                            <img
                                                src={`${baseURL}${room.featured_image}`}
                                                alt=""
                                                loading="lazy"
                                            />{" "}
                                        </Link>
                                        <div
                                            className="to-ho-hotel-con-23"
                                            style={{
                                                backgroundColor:
                                                    "rgba(0, 2, 61, 0.4)",
                                            }}
                                        >
                                            <div className="to-ho-hotel-con-2">
                                                {" "}
                                                <Link
                                                    to={`/rooms/${room?.id}`}
                                                    state={data?.filter(
                                                        (data) =>
                                                            data.id === room.id
                                                    )}
                                                >
                                                    <h4>{room.title}</h4>
                                                </Link>{" "}
                                            </div>
                                            <div className="to-ho-hotel-con-3">
                                                <ul>
                                                    <li>
                                                        <div className="dir-rat-star ho-hot-rat-star">
                                                            {" "}
                                                            Rating:{" "}
                                                            <i
                                                                className="fa fa-star"
                                                                aria-hidden="true"
                                                            ></i>
                                                            <i
                                                                className="fa fa-star"
                                                                aria-hidden="true"
                                                            ></i>
                                                            <i
                                                                className="fa fa-star"
                                                                aria-hidden="true"
                                                            ></i>
                                                            <i
                                                                className="fa fa-star"
                                                                aria-hidden="true"
                                                            ></i>
                                                            <i
                                                                className="fa fa-star"
                                                                aria-hidden="true"
                                                            ></i>{" "}
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="ho-hot-pri" style={{ color:'#fff' }}>
                                                            {parseFloat(
                                                                room.price
                                                            ).toLocaleString()}
                                                        </span>{" "}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RoomsHomePage;
