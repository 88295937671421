import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "config/axios";
import { baseURL } from "config/base";
const AllRooms = () => {
    const [data, setData] = useState([]);
    const getRooms = async () => {
        try {
            const response = await axios.get("rooms");
            setData(response.data.rooms);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getRooms();
    }, []);
    return (
        <>
            <div className="inn-body-section pad-bot-55">
                <div className="container">
                    <div className="row">
                        <div className="page-head">
                            <h2>Rooms</h2>
                            <div className="head-title">
                                <div className="hl-1"></div>
                                <div className="hl-2"></div>
                                <div className="hl-3"></div>
                            </div>
                            <p>Choose a room to book.</p>
                        </div>
                        {data?.map((room) => (
                            <div className="room" key={room.id}>
                                <div className="r1 r-com">
                                    <img
                                        src={`${baseURL}${room.featured_image}`}
                                        alt=""
                                    />
                                </div>
                                <div className="r2 r-com">
                                    <h4>{room.title}</h4>
                                    <div className="r2-ratt">
                                        {" "}
                                        <i className="fa fa-star"></i>{" "}
                                        <i className="fa fa-star"></i>{" "}
                                        <i className="fa fa-star"></i>{" "}
                                        <i className="fa fa-star"></i>{" "}
                                        <i className="fa fa-star"></i>{" "}
                                    </div>
                                    <p>{room.short_desc}</p>
                                </div>
                                <div className="r3 r-com">
                                    <ul>
                                        <li>Wifi </li>
                                        <li>Free Parking</li>
                                        <li>Lounge</li>
                                        <li>Extra Services</li>
                                    </ul>
                                </div>
                                <div className="r4 r-com">
                                    <p className="h4">Price</p>
                                    <p>
                                        <span className="">
                                            UGX:{" "}
                                            {parseFloat(
                                                room.price
                                            ).toLocaleString()}{" "}
                                            / pernight
                                        </span>{" "}
                                    </p>
                                    <p>Non Refundable</p>
                                </div>
                                <div className="r5 r-com">
                                    <Link
                                        to={`/rooms/${room?.id}`}
                                        state={data?.filter(
                                            (data) => data.id === room.id
                                        )}
                                        className="btn btn-danger"
                                    >
                                        More
                                    </Link>{" "}
                                    <Link
                                        to="/booking"
                                        className="btn btn-success"
                                    >
                                        Book
                                    </Link>{" "}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AllRooms;
