import { Link } from "react-router-dom";
import HeaderMobile from "./header-mobile";
import Logo from "assets/logos/heartland.png";
const Header = () => {
    return (
        <>
            <HeaderMobile />
            <div className="menu-section">
                <div className="container">
                    <div className="row">
                        <div className="top-bar">
                            <ul>
                                <li>
                                    <Link to="/about-us" className="fs-2">
                                        About Us
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact-us" className="fs-2">
                                        Contact Us
                                    </Link>
                                </li>

                                <li>
                                    <a href="tel:+256-414-671-210">
                                        <i className="fa fa-phone"></i>{" "}
                                        +256-414-671-210
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto:info@heartlandcottagesarua.com">
                                        <i className="fa fa-envelope "></i>{" "}
                                        info@heartlandcottagesarua.com
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="logo">
                            <Link to="/">
                                <img src={Logo} height="50px" alt="logo" />
                            </Link>
                        </div>
                        <div className="menu-bar">
                            <ul>
                                <li>
                                    <Link to="/">Home </Link>
                                </li>
                                <li>
                                    <Link to="/rooms">Rooms</Link>
                                </li>
                                <li>
                                    <Link to="/services">Services</Link>
                                </li>
                                <li>
                                    <Link to="/restaurants-bar">
                                        Restaurant And Bar
                                    </Link>
                                </li>
                                {/* <li>
                                    <Link to="/amenities">Amenities</Link>
                                </li> */}
                                <li>
                                    <Link to="/gallery">Gallery</Link>
                                </li>
                                <li>
                                    <Link to="/blogs">Blog</Link>
                                </li>
                                <li>
                                    <Link to="/booking" className="btn btn-sm btn-danger waves-effect waves-light" style={{color: "#fff"}}>
                                        Book Now
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;
