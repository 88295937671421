import GeneralBanner from "components/banner";
import Gallerys from "./components/gallerys";

const Gallery = () => {
    return (
        <>
            <GeneralBanner pagename="Gallery" shortdesc={"View Our Gallery"} />
            <Gallerys />
        </>
    );
};

export default Gallery;
