import { Link } from "react-router-dom";
import { baseURL } from "config/base";
const MeetingsAndSpacesHome = ({ data }) => {
    return (
        <>
            <div className="blog hom-com pad-bot-0">
                <div className="container">
                    <div className="row">
                        <div className="hom1-title">
                            <h2>Spaces & Meeting Rooms</h2>
                            <div className="head-title">
                                <div className="hl-1"></div>
                                <div className="hl-2"></div>
                                <div className="hl-3"></div>
                            </div>
                            <p>
                                With our well-equipped event spaces, we are the
                                perfect venue for hosting weddings, conferences,
                                and special occasions
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div>
                            {data?.map((space) => (
                                <div
                                    className="col-md-3 n2-event border-0"
                                    key={space.id}
                                >
                                    <div className="n21-event hovereffect">
                                        {" "}
                                        <img
                                            src={`${baseURL}${space.image}`}
                                            alt=""
                                            loading="lazy"
                                            className="card-top-img img-fluid"
                                            style={{ height: "200px" }}
                                        />
                                        <div className="overlay">
                                            <Link to="/booking">
                                                <span className="ev-book">
                                                    Book Now
                                                </span>
                                            </Link>{" "}
                                        </div>
                                    </div>
                                    <div className="n22-event">
                                        {" "}
                                        <Link to="/booking">
                                            <h4>{space.title}</h4>
                                        </Link>{" "}
                                        <p>{space.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MeetingsAndSpacesHome;
