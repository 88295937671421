import GeneralBanner from "components/banner";
import Restuarant from "./components/restuarant";
import { useState, useEffect } from "react";
import { baseURL } from "config/base";
import axios from "config/axios";
const Bar = () => {
        const [data, setData] = useState([]);
        const getBar = async () => {
            try {
                const response = await axios.get("bar");
                setData(response.data.bar);
            } catch (error) {
                console.log(error);
            }
        };
        useEffect(() => {
            getBar();
        }, []);
    return (
        <>
            <GeneralBanner pagename={"Restaurant & Bar"} shortdesc={"Take a break in our bar and restaurant."}/>
            <Restuarant data={data} baseURL={baseURL} />
        </>
    );
}

export default Bar;
