import { Link } from "react-router-dom";
import Banner_one from "assets/img/heartland-cottages.jpg";
import Banner_two from "assets/img/14.jpg";
import Banner_three from "assets/img/banner.jpg";
import Banner_four from "assets/img/banner2.jpg";
const SliderHome = () => {

    return (
        <>
            <div>
                <div className="slider fullscreen">
                    <ul className="slides">
                        <li>
                            {" "}
                            <img src={Banner_one} alt="" />
                            <div className="caption center-align slid-cap">
                                <h5 className="light grey-text text-lighten-3">
                                    Heartland Cottages Arua
                                </h5>
                                <h2>Your Perfect Retreat in Arua</h2>
                                <p>
                                    Welcome to Heartland Cottages Arua, your
                                    idyllic escape in the heart of Arua. Nestled
                                    amidst serene surroundings, our hotel-motel
                                    offers a perfect blend of comfort and
                                    tranquility, making it an ideal choice for
                                    both leisure and business travelers.
                                </p>{" "}
                                <Link
                                    to="/booking"
                                    className="waves-effect waves-light"
                                >
                                    Book Now
                                </Link>
                                <Link to="/contact-us">Contact Us</Link>{" "}
                            </div>
                        </li>
                        <li>
                            {" "}
                            <img src={Banner_two} alt="" />
                            <div className="caption center-align slid-cap">
                                <h5 className="light grey-text text-lighten-3">
                                    Heartland Cottages Arua.
                                </h5>
                                <h2>Unwind in Comfort</h2>
                                <p>
                                    Experience the warmth of our well-appointed
                                    cottages, thoughtfully designed to cater to
                                    your every need. Each cottage boasts modern
                                    amenities, cozy furnishings, and
                                    breathtaking views of the lush greenery that
                                    surrounds us.
                                </p>{" "}
                                <Link
                                    to="/booking"
                                    className="waves-effect waves-light"
                                >
                                    Book Now
                                </Link>
                                <Link to="/contact-us">Contact Us</Link>{" "}
                            </div>
                        </li>
                        <li>
                            {" "}
                            <img src={Banner_three} alt="" />
                            <div className="caption center-align slid-cap">
                                <h5 className="light grey-text text-lighten-3">
                                    Heartland Cottages Arua.
                                </h5>
                                <h2>Dine with Delight</h2>
                                <p>
                                    Savor delectable dishes prepared by our
                                    skilled chefs at our on-site restaurant.
                                    From local delicacies to international
                                    favorites, our diverse menu will tantalize
                                    your taste buds and leave you craving for
                                    more.
                                </p>{" "}
                                <Link
                                    to="/booking"
                                    className="waves-effect waves-light"
                                >
                                    Book Now
                                </Link>
                                <Link to="/contact-us">Contact Us</Link>{" "}
                            </div>
                        </li>
                        <li>
                            {" "}
                            <img src={Banner_four} alt="" />
                            <div className="caption center-align slid-cap">
                                <h5 className="light grey-text text-lighten-3">
                                    Heartland Cottages Arua.
                                </h5>
                                <h2>Exceptional Service</h2>
                                <p>
                                    At Heartland Cottages Arua, we take pride in
                                    delivering personalized and attentive
                                    service to our guests. Our friendly staff is
                                    always ready to assist you with any request,
                                    making your stay a memorable one.
                                </p>{" "}
                                <Link
                                    to="/booking"
                                    className="waves-effect waves-light"
                                >
                                    Book Now
                                </Link>
                                <Link to="/contact-us">Contact Us</Link>{" "}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default SliderHome;
