import GeneralBanner from "components/banner";
import AllServices from "./components/all-services";

const Service = () => {
    return (
        <>
            <GeneralBanner
                pagename={"Services"}
                shortdesc={"All of our services"}
            />
            <AllServices />
        </>
    );
};

export default Service;
