import { useLocation } from "react-router-dom";
import { baseURL } from "config/base";
const BlogSingle = () => {
     const location = useLocation();
     const data = location.state;
    return (
        <>
            <div
                className="inn-body-section pad-bot-55"
                style={{ marginTop: "100px" }}
            >
                <div className="container">
                    <div className="row inn-page-com">
                        {data?.slice(0, 1)?.map((post) => (
                            <div className="col-md-12 mt-5" key={post.id}>
                                <div className="row inn-services in-blog">
                                    <div className="col-md-4">
                                        <img
                                            src={`${baseURL}${post.featured_image}`}
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-md-8">
                                        <h3>{post.title}</h3>{" "}
                                        <span className="blog-date">
                                            Date:{" "}
                                            {new Date(
                                                post?.created_at
                                            )?.toLocaleDateString("en-GB")}
                                        </span>{" "}
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: post.content,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default BlogSingle;
