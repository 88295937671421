import GeneralBanner from "components/banner";
import { Link } from "react-router-dom";
import Image from "assets/images/IMG_0487-min.jpg";
const AboutUs = () => {
    return (
        <>
            <GeneralBanner pagename={"About Us"} shortdesc={"More About Us"} />
            <div className="inn-body-section">
                <div className="container">
                    <div className="row">
                        <div className="page-head">
                            <h2>About Us</h2>
                            <div className="head-title">
                                <div className="hl-1"></div>
                                <div className="hl-2"></div>
                                <div className="hl-3"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="about-left">
                                <h2>
                                  {" "}
                                    <span>Heart Land Cottages Arua</span>
                                </h2>
                                <p style={{marginTop: "10px"}}>
                                    Welcome to Heartland Cottages Hotel Arua!
                                    Nestled in the heart of Arua, our charming
                                    hotel offers a tranquil and unforgettable
                                    retreat for both leisure and business
                                    travelers. With a commitment to impeccable
                                    service and warm hospitality, Heartland
                                    Cottages Hotel Arua aims to provide a home
                                    away from home for all our guests. Our cozy
                                    cottages are designed to provide a
                                    comfortable and relaxing atmosphere,
                                    complete with modern amenities to cater to
                                    your needs. Each cottage is thoughtfully
                                    decorated to create a serene ambiance,
                                    allowing you to unwind and rejuvenate in
                                    style. At Heartland Cottages Hotel Arua, we
                                    take pride in our delectable cuisine that
                                    showcases local flavors as well as
                                    international delights. Our skilled chefs
                                    craft dishes with the freshest ingredients,
                                    ensuring a delightful culinary experience
                                    that caters to diverse palates.
                                </p>
                                <p>
                                    Whether you're here for a peaceful vacation
                                    or a productive business trip, our dedicated
                                    staff is here to make your stay exceptional.
                                    We believe that every guest is unique, and
                                    we go the extra mile to cater to individual
                                    preferences and requirements. Surrounded by
                                    the beauty of Arua's landscapes and the rich
                                    cultural heritage of the region, Heartland
                                    Cottages Hotel Arua provides a gateway to
                                    exploring the local attractions while
                                    providing a haven of comfort and luxury to
                                    return to. We strive to create memories that
                                    last a lifetime, making us the preferred
                                    choice for travelers seeking a truly
                                    remarkable experience. Come join us at
                                    Heartland Cottages Hotel Arua, where
                                    heartfelt service meets unparalleled
                                    comfort, creating an oasis of serenity in
                                    the bustling city. Your journey of
                                    relaxation and discovery begins with us.
                                </p>
                                <Link to="/contact-us" className="link-btn">
                                    Contact Us
                                </Link>{" "}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="about-right">
                                {" "}
                                <img src={Image} alt="" />{" "}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutUs;
