import "./App.css";
import { Route, Routes } from "react-router-dom";
import Layout from "components/layout";
import Header from "components/header";
import Footer from "components/footer";
import Missing from "components/404";
import HomePage from "pages/home/home";
import RoomsPage from "pages/rooms/room";
import Service from "pages/services/service";
import Bar from "pages/bar/bar";
import Gallery from "pages/gallery/gallery";
import ContactUs from "pages/contact-us/contact";
import AboutUs from "pages/about-us/aboutus";
import Booking from "pages/booking";
import RoomSingle from "pages/rooms/room-single";
import Blog from "pages/blog/blog";
import BlogSingle from "pages/blog/blog-single";
function App() {
    return (
        <>
            <Header />
            <Routes>
                <Route path="" element={<Layout />}>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/rooms" element={<RoomsPage />} />
                    <Route path="/services" element={<Service />} />
                    <Route path="/restaurants-bar" element={<Bar />} />
                    <Route path="/gallery" element={<Gallery />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/booking" element={<Booking />} />
                    <Route path="/rooms/:id" element={<RoomSingle />} />
                    <Route path="/blogs" element={<Blog />} />
                    <Route path="/blogs/:id" element={<BlogSingle />} />
                    <Route path="*" element={<Missing />} />
                </Route>
            </Routes>
            <Footer />
        </>
    );
}

export default App;
